<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div><h6>Last updated: {{ formatDateFromNow(Date(serverLogs.queryTimestamp).toString()) }}</h6></div>
        <v-card v-for="project in serverLogs.projectIds" v-bind:key="project" class="mb-5">
          <v-card-title>
            {{ project }}
            <v-btn
              text
              color="primary"
              :href="'https://console.cloud.google.com/compute/instances?project=' + project"
              target="_blank"
            >
              <v-icon
                color="primary"
              >
                mdi-google-cloud
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table :items="serverLogs.activeServers[project]" :headers="headers" :hide-default-footer="serverLogs.activeServers[project].length < 10">
            <template v-slot:item.lastStartTimestamp="{ item }">
              {{ formatDateFromNow(item.lastStartTimestamp) }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                class="ma-2"
                :color="item['status'] === 'RUNNING' ? 'green' : 'red'"
                text-color="white"
                small
              >
                {{ item['status'] }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  export default {
    name: 'ServerLogs',

    data: () => ({
      headers: [
        { text: 'Name', value: 'name', width: '500px' },
        { text: 'Status', value: 'status', width: '350px' },
        { text: 'Started', value: 'lastStartTimestamp' }
      ]
    }),
    methods: {
      ...mapActions('servers', [
        'getServerLogs'
      ]),
      formatDateFromNow (date) {
        return moment(date).fromNow()
      }
    },
    computed: {
      ...mapGetters('servers', [
        'serverLogs'
      ])
    },
    mounted: function () {
      this.getServerLogs().then(() => {
        console.log('Got serverLogs')
      })
    }
  }
</script>
